import {Group, Title, Text, SimpleGrid, Container, useMantineTheme, Stack, Loader, Image, Box, Grid, Chip, Select, Badge} from '@mantine/core';
import { ProductCard } from '../Cards';
import useAxios from 'axios-hooks'
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { searchSortedData } from '../../lib/sort';
import { useMediaQuery } from '@mantine/hooks';
import useStore from '../../store/useStore';
import classes from './../../styles/Products.module.css';
import axios from 'axios';

export function ProductsByCategory() {
    let { category } = useParams();
    const theme = useMantineTheme();
    let [searchParams, setSearchParams] = useSearchParams();
    let [filterdAllProducts, setFiltredAllProducts] = useState<any>([]);
    const [{ data: allProducts, loading, error }, refetch] = useAxios(
        `${process.env.REACT_APP_API_URL}/posting-products-bycategory/${category}`
    )
    const matches = useMediaQuery('(max-width: 36em)');
    const dataStore = useStore((state: any) => state.store);
    let [dataCategories, setDataCategories] = useState<any>(null);
    let [dataSubCategories, setDataSubCategories] = useState<{label: string, value: string}[]>([]);
    let [dataSubSubCategories, setDataSubSubCategories] = useState<string[]>([]);

    let [subCategory, setSubCategory] = useState<string | null>("");
    let [subSubCategory, setSubSubCategory] = useState<string | null>("");

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/categoryByName/${category}`)
        .then(({data}) => {
            if (data) {
                setDataCategories(data)
                
                let newCategory: {label: string, value: string}[] = []
                for (let index = 0; index < data?.subCategories?.length; index++) {
                    const element = data?.subCategories[index];
                    newCategory.push({label: element?.name, value: element?.name})
                }
                setDataSubCategories(newCategory.sort((a, b) => {
                    var textA = a.label.toUpperCase();
                    var textB = b.label.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }))
            }
        })
        .catch((error) => console.log(error));
    }, [category])

    useEffect(() => {
        if (allProducts && allProducts.length >= 0) {
            
            setFiltredAllProducts(allProducts)
        }
    }, [allProducts, category])
    
    useEffect(() => {
        if((subCategory  && subCategory !== "")) {
            const filterCategory = dataCategories?.subCategories.filter((item: any) => item?.name === subCategory)
            
            if (filterCategory.length > 0 && "subCategories" in filterCategory[0]) {
                const subCategories = filterCategory[0].subCategories
                let newSubCategory: string[] = []

                for (let index = 0; index < subCategories.length; index++) {
                    const element = subCategories[index];
                    newSubCategory.push(element)
                }
                setDataSubSubCategories(newSubCategory.sort((a, b) => {
                    var textA = a.toUpperCase();
                    var textB = b.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }))
            }
        }
    }, [subCategory])

    
    useEffect(() => {
        const search = searchParams.get("search") || null
        
        if (allProducts && allProducts.length > 0 && (search || (subCategory  && subCategory !== "") || (subSubCategory  && subSubCategory !== ""))) {
            if (search && (subCategory  && subCategory !== "") && (subSubCategory  && subSubCategory !== "")) {
                setSearchParams({search})
                let newData = allProducts?.filter((item: any) => item.subCategories.includes(subCategory) && item.subSubCategories.includes(subSubCategory))
                newData = searchSortedData(newData, ["name"], search)
                setFiltredAllProducts(newData)
            } else if ((subCategory  && subCategory !== "") && (subSubCategory  && subSubCategory !== "")) {
                let newData = allProducts?.filter((item: any) => item.subCategories.includes(subCategory) && item.subSubCategories.includes(subSubCategory))
                setFiltredAllProducts(newData)
            } else if (search && (subCategory  && subCategory !== "")) {
                setSearchParams({search})
                let newData = allProducts?.filter((item: any) => item.subCategories.includes(subCategory))
                newData = searchSortedData(newData, ["name"], search)
                setFiltredAllProducts(newData)
            } else if (search && (subSubCategory  && subSubCategory !== "")) {
                setSearchParams({search})
                let newData = allProducts?.filter((item: any) => item.subSubCategories.includes(subSubCategory))
                newData = searchSortedData(newData, ["name"], search)
                setFiltredAllProducts(newData)
            } else if (search) {
                setSearchParams({search})
                let newData = searchSortedData(allProducts, ["name"], search)
                setFiltredAllProducts(newData)
            } else if ((subCategory  && subCategory !== "")) {
                let newData = allProducts?.filter((item: any) => item.subCategories.includes(subCategory))
                setFiltredAllProducts(newData)
            } else if ((subSubCategory  && subSubCategory !== "")) {
                let newData = allProducts?.filter((item: any) => item.subSubCategories.includes(subSubCategory))
                setFiltredAllProducts(newData)
            }
        } else {
            setSearchParams()
            setFiltredAllProducts(allProducts)
        }
    }, [allProducts, searchParams.get("search"), subCategory, subSubCategory])

    if (error) return <p>Error!</p>
    
    return (
        <Box mt={dataStore?.topBar && dataStore?.topBar?.show && dataStore?.topBar?.content && dataStore?.topBar?.content !== "" ? 120 : 80}>
            <Box bg={dataStore?.information?.backgroundColor || "#645cbb"} mih={250}>
                <Stack justify='center' align='center' w={"100%"} h={250}>
                    <Title order={1} size={matches ? 21 : 30} ta="right" c={"#fff"}>جميع منتجات: {category}</Title>
                    <Group justify='center' align='center'>
                        <Select
                            placeholder={"الفئة الرئيسية"}
                            size={matches ? "sm" : 'md'}
                            value={subCategory} onChange={setSubCategory}
                            data={dataSubCategories}
                            clearable={true}
                            styles={{
                                dropdown: {
                                    minWidth: 150
                                },
                            }}
                        />
                        <Select
                            placeholder={"الفئة الفرعية"}
                            size={matches ? "sm" : 'md'}
                            value={subSubCategory} onChange={setSubSubCategory}
                            data={dataSubSubCategories}
                            clearable={true}
                            styles={{
                                dropdown: {
                                    minWidth: 150
                                },
                            }}
                        />
                    </Group>
                </Stack>
            </Box>

            <Container size="xl" mt={20}>
                {filterdAllProducts?.length > 0
                    ? <Group justify='space-between' my={20} w={"100%"} align='center'>
                        <Title order={4} size={matches ? 16 : 18} ta="center" className={classes.categoryTitle}>عدد المنتاجات:</Title>
                        <Badge variant="light" size='lg' color='gray.7' radius={'md'}>{filterdAllProducts?.length}</Badge>
                    </Group>
                    : null
                }

                {loading
                    ? <Stack align='center' justify='center' mt={50}>
                        <Loader color={dataStore?.information?.backgroundColor || "#645cbb"} size="md" type="bars" />
                    </Stack>
                    : null
                }

                {filterdAllProducts && filterdAllProducts?.length > 0
                    ? <SimpleGrid cols={{ base: 2, sm: 3, md: 4, lg: 5, xl: 5 }} spacing="sm" >
                        {filterdAllProducts?.map((item: any, index: number) => (
                            <ProductCard
                                key={index}
                                id={item._id}
                                title={item?.name}
                                image={`${process.env.REACT_APP_API_URL_IMAGES}/${item?.thumbnail}`}
                                price={item?.price }
                                priceAfterDiscount={item.priceAfterDiscount}
                                rating={item?.rating}
                            />
                        ))}
                        </SimpleGrid>
                    : <Stack align='center' justify='center' mt={50}>
                        <Image src={"/shopping-trolley.png"} h={"100px"} w={"100px"} fit='contain' />
                    </Stack>
                }
            </Container>
        </Box>
    );
}