import { Text, Container, Group, Image, useMantineTheme, Title, Box } from '@mantine/core';
import { IconMapPinFilled, IconPhoneFilled, IconMailFilled } from '@tabler/icons-react';
import classes from './../../styles/Footer.module.css';
import { useEffect, useRef, useState } from 'react';
import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import Cookies from 'universal-cookie';
import useStore from '../../store/useStore';
import axios from 'axios';
import { useMediaQuery } from '@mantine/hooks';
import { Link } from 'react-router-dom';
const cookies = new Cookies(null, { path: '/' });


export function BannerHeader() {
    const theme = useMantineTheme();
    const autoplay = useRef(Autoplay({ delay: 5000 }));
    const autoplay2 = useRef(Autoplay({ delay: 3000 }));
    const dataStore = useStore((state: any) => state.store);
    const matches = useMediaQuery('(max-width: 36em)');
    let [allCategories, setAllCategories] = useState<any[]>([]);
  
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/category`)
        .then(({data}) => {
            setAllCategories(data)
        })
        .catch((error) => console.log(error));
    }, [])
    
    if (dataStore?.header?.length === 0) {
        return (<></>)
    }

    return (
        <section style={{marginTop: dataStore?.topBar && dataStore?.topBar?.show && dataStore?.topBar?.content ? 120 : 80}}>
            {dataStore?.header?.filter((item: any) => item !== "")?.length > 1
                ? <Carousel dir='ltr' loop dragFree align="start" slideSize={{ base: '100%' }} plugins={[autoplay.current]} onMouseEnter={autoplay.current.stop} onMouseLeave={autoplay.current.reset}>
                    {dataStore?.header?.map((item: any, index: number) => (
                        <Carousel.Slide key={index}>
                            <Image src={`${process.env.REACT_APP_API_URL_IMAGES}/${item}`} alt={""} width={"100%"} mah={"calc(100vh - 80px)"} fit={'fill'}  />
                        </Carousel.Slide>
                    ))}
                </Carousel>
                : <Image src={`${process.env.REACT_APP_API_URL_IMAGES}/${dataStore?.header?.filter((item: any) => item !== "")?.[0]}`} alt={""} width={"100%"} mah={"calc(100vh - 80px)"} fit={'fill'}  />
            }
            <Container size="xl" my={20}>
                <Carousel
                    dir={'ltr'} height={matches ? "60px" : "100px"}
                    slideSize={{ base: '25%', sm: '20%', md: '20%', lg: '20%', xl: '15%' }}
                    slideGap={{ base: 0, sm: 'md' }} loop align="center"
                    styles={{controls: {display: 'none'}}}
                    slidesToScroll={1} plugins={[autoplay2.current]}
                    onMouseEnter={autoplay2.current.stop} onMouseLeave={autoplay2.current.reset}
                >
                    {allCategories.map((item, index) => (
                        <Carousel.Slide key={index}>
                            <Link to={`/products/${item.name}`} preventScrollReset={true} >
                                <Box w={matches ? "60px" : "100px"} h={matches ? "60px" : "100px"} bg={"#fff"}>
                                    <Image src={`${process.env.REACT_APP_API_URL_IMAGES}/${item.image}`} h={"100%"} w={"100%"} fit='contain' />
                                </Box>
                            </Link>
                        </Carousel.Slide>
                    ))}
                </Carousel>
            </Container>
        </section>
    );
}